import UIGrid from '@material-ui/core/Grid';
import Markdown from 'markdown-to-jsx';
import { ArticleJsonLd, BlogJsonLd, BreadcrumbJsonLd, CorporateContactJsonLd, FAQPageJsonLd, LocalBusinessJsonLd, NewsArticleJsonLd, ProductJsonLd, SocialProfileJsonLd } from 'next-seo';
import dynamic from 'next/dynamic';
import React from 'react';
import Hero from '../../../reusableSections/Hero';
import HeroConverter from '../../../reusableSections/HeroConverter';
import HeroResource from '../../../reusableSections/HeroResource';
import Footer from '../../Footer/Footer';
import JsonSeo from '../SeoComponents/Seo';
import HeroHeader from '../../../reusableSections/new/HeroHeader';
import ColumnImage from '../../../reusableSections/new/ColumnImage';
import UserReviewsSection from '../../../reusableSections/new/UserReviewsSection/UserReviewsSection';
import FullWidthSection from '../../../reusableSections/new/FullWidthSection/FullWidthSection';
import HalfWidthSection from '../../../reusableSections/new/HalfWidthSection/HalfWidthSection';
import TabsSection from '../../../reusableSections/new/TabsSection/TabsSection';
import HeroSection from '../../../reusableSections/new/HeroSection/HeroSection';
import DownloadAppSection from '../../../reusableSections/new/DownloadAppSection/DownloadAppSection';
import HalfColumnSection from '../../../reusableSections/new/HalfColumnSection';
import FullColumnSection from '../../../reusableSections/new/FullColumnSection';
import Slider from '../../../reusableSections/new/Slider';
import FAQList from '../../../reusableSections/new/FAQList';
import TopBar from '../../TopBar/TopBar';
import Disclaimer from '../../../reusableSections/Disclaimer';
import PromotionalHero from '../../Hero/PromotionalHero';
import CashPickupLandscapeImage from '../../../sections/CashPickupLandscapeImage';
import PickupLocationsMap from '../../../reusableSections/PickupLocationsMap';
import CashPickupLocationsList from '../../../sections/CashPickupLocationsList';
import DownloadAppVariableSection from '../../../sections/DownloadAppVariableSection';
import DownloadAppSections from '../../../reusableSections/new/DownloadAppSection';
import HeroFullImageSection from '../../business-perks/HeroFullImageSection';
import GiftOperators from '../../GiftCardPublic/GiftOperators';
import { Grid } from '@material-ui/core';
import ConverterGraph from '../../../reusableSections/new/ConverterGraph/ConverterGraph';
import Forecast from '../../../reusableSections/new/Forecast/Forecast';

const Column = dynamic(() => import('../../../../common/rowColumn').then(module => module.Column))
const FullColumn = dynamic(() => import('../../../../common/rowColumn').then(module => module.FullColumn))
const HalfColumn = dynamic(() => import('../../../../common/rowColumn').then(module => module.HalfColumn))
const HalfOrFullColumn = dynamic(() => import('../../../../common/rowColumn').then(module => module.HalfOrFullColumn))
const Row = dynamic(() => import('../../../../common/rowColumn').then(module => module.Row))
const PromoPopUp = dynamic(() => import('../../../../common/PromoPopUp'))
const PromoSignUp = dynamic(() => import('../../../../common/PromoSignUp'))
const ImageText = dynamic(() => import('../../../reusableSections/ImageText'))
const AllCountriesSection = dynamic(() => import('../../../reusableSections/AllCountriesSection'))
const BoxList = dynamic(() => import('../../../reusableSections/BoxList'))
const CodeDissector = dynamic(() => import('../../../reusableSections/CodeDissector'))
const CurvedImage = dynamic(() => import('../../../reusableSections/CurvedImage'))
const CustomSectionItem = dynamic(() => import('../../../reusableSections/CustomSectionItem'))
const FAQ = dynamic(() => import('../../../reusableSections/FAQ'))
const FullColumnInfo = dynamic(() => import('../../../reusableSections/FullColumnInfo'))
const HowItWorks = dynamic(() => import('../../../reusableSections/HowItWorks'))
const InfoColumnsWithList = dynamic(() => import('../../../reusableSections/InfoColumnsWithList'))
const OtherServices = dynamic(() => import('../../../reusableSections/OtherServices'))
const ProConList = dynamic(() => import('../../../reusableSections/ProConList'))
const ProsCons = dynamic(() => import('../../../reusableSections/ProsCons'))
const Section = dynamic(() => import('../../../reusableSections/Section'))
const Steps = dynamic(() => import('../../../reusableSections/Steps'))
const TextWithBG = dynamic(() => import('../../../reusableSections/TextWithBG'))
const TrustPilotWidget = dynamic(() => import('../../../reusableSections/TrustPilotWidget'))
const DownloadAppsSection = dynamic(() => import('../../../sections/DownloadAppsSection'))
const CustomList = dynamic(() => import('../../CustomListWebsite/CustomList'))
const Cards = dynamic(() => import('../sections/Cards'))
const ConversionTable = dynamic(() => import('../sections/ConversionTable'))
const SimpleConversionTable = dynamic(() => import('../sections/SimpleConversionTable'))
const Features = dynamic(() => import('../sections/Features'))
const CurrencyListSection = dynamic(() => import('../../../../common/CurrencyListSection'))
const RatesGraph = dynamic(() => import('../../../../rates/RatesGraph'))
const BlogSection = dynamic(() => import('../../../reusableSections/new/BlogSection/BlogSection'))

import NavigationBarSection from '../../../../../remitbee/sections/NavigationBarSection/NavigationBarSection';
import IBANExamples from '../../../reusableSections/new/IBANCalculator/IBANExamples';
import FooterSection from '../../../../../remitbee/sections/FooterSection/FooterSection';

function DynamicContent({ data, isMobileServer }) {
  const { content, SEO, countries } = data;

  if (!content) return null

  let dynamicContent = content

  const markdownOptions = {
    overrides: {
      Seo: { component: () => null },
      ArticleJsonLd: { component: ArticleJsonLd },
      BlogJsonLd: { component: BlogJsonLd },
      BreadcrumbJsonLd: { component: BreadcrumbJsonLd },
      CorporateContactJsonLd: { component: CorporateContactJsonLd },
      FAQPageJsonLd: { component: FAQPageJsonLd },
      LocalBusinessJsonLd: { component: LocalBusinessJsonLd },
      // JobPostingJsonLd: { component: JobPostingJsonLd },
      NewsArticleJsonLd: { component: NewsArticleJsonLd },
      SocialProfileJsonLd: { component: SocialProfileJsonLd },
      ProductJsonLd: { component: ProductJsonLd },
      CurrencyListSection: { component: (props) => <CurrencyListSection {...props} countries={countries} /> },
      Row: { component: Row },
      Column: { component: Column },
      HalfColumn: { component: HalfColumn },
      FullColumn: { component: FullColumn },
      HalfOrFullColumn: { component: HalfOrFullColumn },
      Hero: { component: Hero },
      HeroConverter: { component: HeroConverter },
      HeroResource: { component: HeroResource },
      CurvedImage: { component: CurvedImage },
      Cards: { component: Cards },
      Features: { component: Features },
      InfoArea: { component: ImageText },
      Steps: { component: Steps },
      FullColumnInfo: { component: FullColumnInfo },
      InfoColumnsWithList: { component: InfoColumnsWithList },
      ConversionTable: { component: (props) => <ConversionTable {...props} countries={countries} /> },
      DownloadAppsSection: { component: DownloadAppsSection },
      Section: { component: Section },
      // Header: { component: DefaultHeader },
      Header: { component: (props) => <NavigationBarSection isMobileServer={isMobileServer} {...props} /> },
      Faq: { component: FAQ },
      CodeDissector: { component: CodeDissector },
      CustomList: { component: CustomList },
      ProConList: { component: ProConList },
      ProsCons: { component: ProsCons },
      TrustPilotWidget: { component: TrustPilotWidget },
      HowItWorks: { component: HowItWorks },
      OtherServices: { component: OtherServices },
      CustomSectionItem: { component: CustomSectionItem },
      UIGrid: { component: UIGrid },
      TextWithBG: { component: TextWithBG },
      AllCountriesSection: { component: (props) => <AllCountriesSection {...props} countries={countries} /> },
      BoxList: { component: BoxList },
      RatesGraph: { component: RatesGraph },
      //new designs
      TopBar: { component: TopBar },
      HeroHeader: { component: HeroHeader },
      ColumnImage: { component: ColumnImage },
      FullColumnSection: { component: FullColumnSection },
      HalfColumnSection: { component: HalfColumnSection },
      Slider: { component: Slider },
      FaqList: { component: FAQList },
      PromoPopUp: { component: PromoPopUp },
      PromoSignUp: { component: PromoSignUp },
      SimpleConversionTable: { component: SimpleConversionTable },
      Disclaimer: { component: Disclaimer },
      PromotionalHero: { component: PromotionalHero },
      CashPickupLandscapeImage: { component: CashPickupLandscapeImage },
      PickupLocationsMap: { component: PickupLocationsMap },
      CashPickupLocationsList: { component: CashPickupLocationsList },
      DownloadAppVariableSection: { component: DownloadAppVariableSection },
      DownloadAppBannerSection: { component: DownloadAppSections },
      HeroFullImageSection: { component: HeroFullImageSection },
      GiftOperators: { component: GiftOperators },
      Grid: { component: Grid },
      FullWidthSection: { component: FullWidthSection },
      HalfWidthSection: { component: (props) => <HalfWidthSection {...props} isMobileServer={isMobileServer} /> },
      TabsSection: { component: TabsSection },
      HeroSection: { component: (props) => <HeroSection {...props} isMobileServer={isMobileServer} /> },
      DownloadAppSection: { component: DownloadAppSection },
      UserReviewsSection: { component: UserReviewsSection },
      BlogSection: { component: BlogSection },
      ConverterGraph: { component: ConverterGraph },
      Forecast: { component: Forecast },
      IBANExamples: { component: IBANExamples },
    }
  };

  const get_schema = SEO["@type"] != null && <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(SEO) }} />

  return (
    <>
      {get_schema}
      <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <JsonSeo {...SEO} />
        <Markdown
          children={dynamicContent}
          options={markdownOptions}
        />
        <FooterSection isMobileServer={isMobileServer} />
      </div>
    </>
  );
}

export default DynamicContent;
