import React from 'react';
import { ArticleJsonLd, BlogJsonLd, BreadcrumbJsonLd, CorporateContactJsonLd, FAQPageJsonLd, LocalBusinessJsonLd, LogoJsonLd, NewsArticleJsonLd, NextSeo, ProductJsonLd, SocialProfileJsonLd } from 'next-seo';

const Seo = (props) => {

    return (
        <>
            {props.Seo && <NextSeo {...props.Seo} />}
            {props.ArticleJsonLd && <ArticleJsonLd {...props.ArticleJsonLd} />}
            {props.ArticleJsonLd && <ArticleJsonLd {...props.ArticleJsonLd} />}
            {props.BlogJsonLd && <BlogJsonLd {...props.BlogJsonLd}/>}
            {props.BreadcrumbJsonLd && <BreadcrumbJsonLd {...props.BreadcrumbJsonLd}/>}
            {props.CorporateContactJsonLd && <CorporateContactJsonLd {...props.CorporateContactJsonLd}/>}
            {props.FAQPageJsonLd && <FAQPageJsonLd {...props.FAQPageJsonLd}/>}
            {props.LogoJsonLd && <LogoJsonLd {...props.LogoJsonLd}/>}
            {props.LocalBusinessJsonLd && <LocalBusinessJsonLd {...props.LocalBusinessJsonLd}/>}
            {/* {props.JobPostingJsonLd && <JobPostingJsonLd {...props.JobPostingJsonLd}/>} */}
            {props.NewsArticleJsonLd && <NewsArticleJsonLd {...props.NewsArticleJsonLd}/>}
            {props.SocialProfileJsonLd && <SocialProfileJsonLd {...props.SocialProfileJsonLd}/>}
            {props.ProductJsonLd && <ProductJsonLd {...props.ProductJsonLd}/>}
        </>
    )
}

export default Seo;
