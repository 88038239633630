import React, { useEffect, useState } from 'react'
import Dialog from '../../../remitbee/components/dialogs/Dialog'
import Box from '../../../remitbee/components/box/Box'
import classes from '../Destination/Destination.module.scss'
import Typography from '../../../remitbee/components/typography/Typography'
import Button from '../../../remitbee/components/button/Button'
import theme from '../../../remitbee/theme/Theme'
import CloseIcon from '../../../remitbee/icons/CloseIcon'
import Input from '../../../remitbee/components/input/Input'
import SearchIcon from '../../../remitbee/icons/SearchIcon'
import Divider from '../../../remitbee/components/divider/Divider'
import Flag from '../../../remitbee/components/flag/Flag'

const CoverageDialog = ({ t, openDialog, setOpenDialog, countries }) => {
    const [searchText, setSearchText] = useState(null);
    const [filteredList, setFilteredList] = useState([]);

    useEffect(() => {
      if(countries) setFilteredList(countries)
    }, [countries])

    useEffect(() => {
        if (searchText) {
          const filteredData = countries.filter(country => country?.name.toLowerCase().includes(searchText.toLowerCase()))
          setFilteredList(filteredData)
        } else {
          setFilteredList(countries)
        }
      }, [searchText]);


    return (
        <Box className={classes['rb-dialog-container']}>
            <Box className={classes['rb-modal']}>
                <Box>
                <Box pr={5} pl={5} pt={5} className={classes['rb-modal-header']}>
                    <Typography id='filters-dialog-header' variant='h2' weight='semibold' color={theme.palette.primary.navy}>{t('e-sim:supported_countries')}</Typography>
                    <Button onClick={() => setOpenDialog(false)} id='closeFilters' variant='text'><CloseIcon width={24} height={24} /></Button>
                </Box>
                <Box mt={4} mb={4} pr={5} pl={5} >
                    <Input
                        fullWidth
                        id="search-transactions"
                        type="text"
                        placeholder='Search...'
                        onChange={(e) => setSearchText(e.target.value)}
                        value={searchText}
                        startAdornment={<SearchIcon width={20} height={20} color={theme.palette.input.body} />}
                        endAdornment={searchText && <Box display="flex" alignItems="center" onClick={() => {
                          setSearchText('');
                      }}><CloseIcon color={theme.palette.primary.blue} width={20} height={20} /></Box>}
                    />
                </Box>
                <Divider />
                <Box pr={5} pl={5} pb={5} className={classes['rb-device-list-container']} gap={0}>
                  {filteredList.length ?  filteredList.map((country) => (
                       <Box id={country?.name} pt={3} pb={3} display='flex' gap={12}>
                          <img src={country?.flagUrl} alt="flag" />
                          <Typography variant='label1' color={theme.palette.primary.navy}>{country?.name}</Typography>
                       </Box>
                    )) : <Typography variant='label1' weight='medium' color={theme.palette.input.placeholder}>No results found</Typography>}
                </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default CoverageDialog